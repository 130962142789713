/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';

import en from '@youship/i18n/marketing-website/en';
import pt from '@youship/i18n/marketing-website/pt';
import flattenMessages from '@youship/utils/flatten-messages';

import CookiesAlert from 'components/cookies-alert';
import Navbar from 'components/navbar';
import Footer from 'components/footer';

import './styles.scss';

const messages = {
  en: flattenMessages(en),
  pt: flattenMessages(pt)
};

const Layout = ({ children, locale }) => (
  <IntlProvider
    locale={locale}
    messages={messages[locale]}
  >
    <div className="layout">
      <Navbar locale={locale} />
      <main>
        {children}
      </main>
      <CookiesAlert />
      <Footer />
    </div>
  </IntlProvider>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
