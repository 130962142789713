import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const OffScreenSlider = ({
  block,
  children,
  show,
  side,
  onClose
}) => {
  const ref = useRef(null);

  const clickListener = (event) => {
    if (!(ref.current).contains(event?.target)) {
      handleClickOutside();
    }
  };

  const handleClickOutside = () => {
    onClose();
  };

  useEffect(() => {
    document.addEventListener('click', clickListener);

    return () => {
      document.removeEventListener('click', clickListener);
      document.body.classList.remove('no-scroll');
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { body } = document;

    if (show) body.classList.add('no-scroll');
    else body.classList.remove('no-scroll');
  }, [show]);

  return (
    <>
      <div className={`off-screen-slider__overlay${show && side ? ' off-screen-slider__overlay--show' : ''}`} />
      <div
        ref={ref}
        className={`off-screen-slider${
          show && side ? ' off-screen-slider--show' : ''}${
          side === 'left' ? ' off-screen-slider--left' : ''}${
          side === 'right' ? ' off-screen-slider--right' : ''}${
          block ? ' off-screen-slider--block' : ''}
        `}
      >
        <div className="off-screen-slider__content">
          {children}
        </div>
      </div>
    </>
  );
};

OffScreenSlider.propTypes = {
  block: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]),
  onClose: PropTypes.func,
  show: PropTypes.bool,
  side: PropTypes.oneOf(['left', 'right'])
};

OffScreenSlider.defaultProps = {
  block: false,
  children: null,
  onClose: () => {},
  show: false,
  side: null
};

export default OffScreenSlider;
