import SessionStorageManager from '@youship/utils/SessionStorageManager';

import BaseApi from '..';
import {
  USER_ADD_PHOTO,
  USER_CHANGE_PASSWORD,
  USER_DELIVERIES_ACTIVE,
  USER_DELIVERIES_ALL,
  USER_DELIVERIES_CURRENT,
  USER_DELIVERIES_DRAFT,
  USER_DELIVERIES_EXPORT,
  USER_DELIVERIES_HISTORY,
  USER_EDIT,
  USER_PROFILE,
  USER_REMOVE_PHOTO,
  USER_RESUME,
  USER_TRACK,
  USER_WORKING
} from '../endpoints';

const APPKEY = SessionStorageManager.getAuthenticationToken();

const USER_TYPE_DRIVER = 'driver';
const USER_TYPE_SHOP = 'shop';
const USER_TYPE_TRANSPORTER = 'transporter';
const USER_TYPE_USER = 'user';

export {
  USER_TYPE_DRIVER,
  USER_TYPE_SHOP,
  USER_TYPE_TRANSPORTER,
  USER_TYPE_USER
};

export default class UserApi extends BaseApi {
  /* eslint-disable camelcase */

  static resume = () => this.post(USER_RESUME);

  static currentDeliveries = () => this.post(USER_DELIVERIES_CURRENT);

  static activeDeliveries = ({
    grouped = '', // optional: true/false -> default=true
    str = '', // optional
    reference = '', // optional
    datefrom = '', // optional
    dateto = '', // optional
    user_code = '', // optional
    pages = { // optional
      current: 0,
      resultsbypage: 0
    }
  }) => this.post(USER_DELIVERIES_ACTIVE, {
    grouped,
    str,
    reference,
    datefrom,
    dateto,
    user_code,
    pages
  });

  static historyDeliveries = ({
    grouped = '', // optional: true/false -> default=true
    str = '', // optional
    reference = '', // optional
    datefrom = '', // optional
    dateto = '', // optional
    user_code = '', // optional
    pages = { // optional
      current: 0,
      resultsbypage: 0
    }
  }) => this.post(USER_DELIVERIES_HISTORY, {
    grouped,
    str,
    reference,
    datefrom,
    dateto,
    user_code,
    pages
  });

  static draftDeliveries = ({
    grouped = '', // optional: true/false -> default=true
    str = '', // optional
    reference = '', // optional
    datefrom = '', // optional
    dateto = '', // optional
    user_code = '', // optional
    pages = { // optional
      current: 0,
      resultsbypage: 0
    }
  }) => this.post(USER_DELIVERIES_DRAFT, {
    grouped,
    str,
    reference,
    datefrom,
    dateto,
    user_code,
    pages
  });

  static allDeliveries = ({
    grouped = '', // optional: true/false -> default=true
    str = '', // optional
    reference = '', // optional
    datefrom = '', // optional
    dateto = '', // optional
    user_code = '', // optional
    pages = { // optional
      current: 0,
      resultsbypage: 0
    }
  }) => this.post(USER_DELIVERIES_ALL, {
    grouped,
    str,
    reference,
    datefrom,
    dateto,
    user_code,
    pages
  });

  // NOTE: this get function will not fit for file download;
  // this will be changed in the future.

  static deliveriesExport = ({
    str, // optional
    reference, // optional
    datefrom, // optional
    dateto, // optional
    user_code // optional
  }) => this.get(`${USER_DELIVERIES_EXPORT}/${APPKEY}?str=${str}&reference=${reference}&datefrom=${datefrom}&dateto=${dateto}&user_code=${user_code}`);

  static editProfile = ({
    company, // optional
    countrycode,
    firstname,
    idcountry,
    idphonecode,
    lastname,
    phonenumber,
    phonecode
  }) => {
    const data = {
      firstname,
      lastname,
      phonenumber
    };

    if (company) data.company = company;

    if (idphonecode) data.idphonecode = idphonecode;
    else if (phonecode) data.phonecode = phonecode;

    if (idcountry) data.idcountry = idcountry;
    else if (countrycode) data.countrycode = countrycode;

    return this.post(USER_EDIT, data);
  };

  static addPhoto = ({
    image
  }) => this.post(USER_ADD_PHOTO, {
    image
  });

  static removePhoto = () => this.post(USER_REMOVE_PHOTO);

  static changePassword = ({
    curpassword,
    password
  }) => this.post(USER_CHANGE_PASSWORD, {
    curpassword,
    password
  });

  static trackUser = () => this.post(USER_TRACK);

  static setWorkingStatus = ({
    working
  }) => this.post(USER_WORKING, {
    working
  });

  static publicProfile = ({
    user_code
  }) => this.post(USER_PROFILE, {
    user_code
  });
}
