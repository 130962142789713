import React from 'react';
import PropTypes from 'prop-types';

import Button from '../button';

import avatarImage from '../../../assets/images/avatar.jpg';

import './styles.scss';

const AvatarButton = ({ imageUrl, name, onClick, text }) => (
  <Button
    classNames="avatar-button"
    light
    onClick={onClick}
  >
    {(!!imageUrl || !!avatarImage) && (
      <div className="avatar-button__image-wrapper">
        <img
          alt={name}
          className="avatar-button__image"
          src={imageUrl || avatarImage}
        />
      </div>
    )}
    {text || null}
    {!text && (
      <>
        <span className="avatar-button__greeting">
          Hello
          {`${name ? ',\xa0' : ''}`}
        </span>
        {name || null}
      </>
    )}
  </Button>
);

AvatarButton.propTypes = {
  imageUrl: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ])
};

AvatarButton.defaultProps = {
  imageUrl: null,
  name: null,
  onClick: () => {},
  text: null
};

export default AvatarButton;
