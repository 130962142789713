import React, { useState } from 'react';
import PropTypes from 'prop-types';
import locales from '../../i18n/locales';

import Select from '../objects/select';

import './styles.scss';

const localeKeys = Object.keys(locales);
const localeOptions = localeKeys.map(key => ({
  text: locales[key].locale,
  value: key
}));

const LocaleSelect = ({ classNames, desktopSelector, onChange, selected, showLanguageList }) => {
  const languageOptions = localeOptions.map((option) => {
    const icon = option.value === 'en' ? 'https://flag.pk/flags/4x3/us.svg' : `https://flag.pk/flags/4x3/${option.value}.svg`;

    return {
      ...option,
      icon
    };
  });

  const defaultLocaleKey = localeKeys.find(key => locales[key].default);
  const [selectedOption, setSelectedOption] = useState(defaultLocaleKey || '');

  const handleChange = (value, icon, text) => {
    setSelectedOption(value);
    onChange(value, icon, text);
  };

  const selectValue = selected === null ? selectedOption : selected;

  return (
    <div className={`locale-select${classNames ? ` ${classNames}` : ''}${showLanguageList ? ' d-block' : ''}`}>
      {showLanguageList ?
        languageOptions.map((option, index) => (
          <li
            key={index}
            className={`locale-select__list-item${desktopSelector ? ' locale-select__list-item--desktop-selector' : ''}`}
            value={option.value}
            onClick={() => handleChange(option.value, option.icon, option.text ?? '')}
          >
            <img
              alt="Language Icon"
              className="locale-select__list-icon"
              src={option.icon}
            />
            <span>
              {option.text}
            </span>
          </li>
        )) : (
          <Select
            autoWidth
            classNames="locale-select__select"
            displaySelectedOptionAsIcon
            horizontalPosition="right"
            inputId="language-select"
            noBorder
            noVerticalPadding
            options={languageOptions}
            value={selectValue}
            onChange={option => handleChange(option ?? '')}
          />
        )}
    </div>
  );
};

LocaleSelect.propTypes = {
  classNames: PropTypes.string,
  desktopSelector: PropTypes.bool,
  onChange: PropTypes.func,
  selected: PropTypes.string,
  showLanguageList: PropTypes.bool
};

LocaleSelect.defaultProps = {
  classNames: null,
  desktopSelector: false,
  onChange: () => { },
  selected: null,
  showLanguageList: false
};

export default LocaleSelect;
