import React, { useEffect, useState } from 'react';
import Link from 'utils/localized-link';
import Cookies from 'universal-cookie';
import { FormattedMessage, useIntl } from 'react-intl';

import Alert from '@youship/components/objects/alert';
import Button from '@youship/components/objects/button';

import './styles.scss';

const cookies = new Cookies();

const COOKIES_DOMAIN = process.env.GATSBY_COOKIES_DOMAIN;

const PRIVACY_CONSENT_COOKIE = 'YOUSHIP_PRIVACY_CONSENT';
const PRIVACY_CONSENT_COOKIE_VALUE = 'true';

const CookiesAlert = () => {
  const [show, setShow] = useState(false);

  const closeAlert = () => {
    setShow(false);
  };

  const getConsentCookie = () => {
    const name = `${PRIVACY_CONSENT_COOKIE}=`;

    const decodedCookie = decodeURIComponent(document.cookie);
    const decodedCookieSplitted = decodedCookie.split(';');

    for (let i = 0; i < decodedCookieSplitted.length; i += 1) {
      let cookie = decodedCookieSplitted[i];

      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1);
      }

      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length);
      }
    }

    return null;
  };

  const handleAlertClose = () => {
    setConsentCookie();
    closeAlert();
  };

  const handleAlertSubmit = () => {
    setConsentCookie();
    closeAlert();
  };

  const setConsentCookie = () => {
    cookies.set('YOUSHIP_PRIVACY_CONSENT', PRIVACY_CONSENT_COOKIE_VALUE, { path: '/', domain: COOKIES_DOMAIN });
  };

  useEffect(() => {
    const consentCookie = getConsentCookie();

    if (!consentCookie || consentCookie !== PRIVACY_CONSENT_COOKIE_VALUE) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, []);

  const intl = useIntl();

  return (
    <Alert
      className="cookies-alert"
      content={intl.formatMessage({ id: 'cookies.text' })}
      footer={
        <>
          <Link
            className="cookies-alert__link"
            to="/privacy"
          >
            <FormattedMessage id="cookies.textlink" />
          </Link>
          <Button
            context="primary"
            small
            onClick={handleAlertSubmit}
          >
            <FormattedMessage id="cookies.accept" />
          </Button>
        </>
      }
      show={show}
      title={intl.formatMessage({ id: 'cookies.title' })}
      onClose={handleAlertClose}
    />
  );
};

export default CookiesAlert;
