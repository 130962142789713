import React from 'react';
import PropTypes from 'prop-types';

import Button from '../button';

import closeIcon from '../../../assets/images/icons/close.svg';

import './styles.scss';

const Alert = ({ classNames, content, footer, onClose, onSubmit, show, submitButtonText, title }) => {
  const handleCloseIconClick = () => {
    onClose();
  };

  const handleCloseIconKeyDown = (event) => {
    if (event.keyCode === 13) {
      onClose();
    }
  };

  return (
    <>
      {show && (
        <div className={`alert${classNames ? ` ${classNames}` : ''}`}>
          <div className="alert__header">
            <div className="alert__title">
              {title}
            </div>
            <button
              onClick={handleCloseIconClick}
              onKeyDown={handleCloseIconKeyDown}
              className="alert__close-button"
            >
              <img
                className="alert__close-icon"
                src={closeIcon}
                alt="Close Icon"
              />
            </button>
          </div>
          <div className="alert__content">
            {content}
          </div>
          <div className="alert__footer">
            {footer ? (
              <>
                {footer}
              </>
            ) : (
              <Button
                small
                context="primary"
                onClick={onSubmit}
              >
                {submitButtonText}
              </Button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

Alert.propTypes = {
  classNames: PropTypes.string,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  footer: PropTypes.element,
  show: PropTypes.bool,
  submitButtonText: PropTypes.string,
  title: PropTypes.string
};

Alert.defaultProps = {
  classNames: null,
  content: null,
  show: false,
  submitButtonText: 'Submit',
  title: null
};

export default Alert;
